import React from "react";
import Hero from "../globals/Hero";
import homeImg from "../../images/homeBaner.jpeg";
import Banner from "../globals/Banner";
import { PrimaryBtn } from "../globals/Buttons";

const Header = () => {
  return (
    <Hero $img={homeImg}>
      <Banner
        greeting="welcome to"
        title="beachwalk resort"
        text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa."
      >
        <PrimaryBtn $top="1rem">view details</PrimaryBtn>
        {/* <PrimaryBtn as="a" href="https://www.google.com">view details</PrimaryBtn> */}
      </Banner>
    </Hero>
  );
};

export default Header;
