import room1 from "../../images/basicRoom.jpeg";
import room2 from "../../images/presidentalRoom.jpeg";
import room3 from "../../images/queenRoom.jpeg";

const roomsData = [
  {
    id: 1,
    img: room1,
    title: "basic economy",
    info: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    price: 125,
  },
  {
    id: 2,
    img: room2,
    title: "presidental suite",
    info: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    price: 250,
  },
  {
    id: 3,
    img: room3,
    title: "standart queen room",
    info: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",
    price: 375,
  },
];

export default roomsData;