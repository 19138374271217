import { createGlobalStyle } from "styled-components";
import { setColor, setFont } from "../../styles";

const Globals = createGlobalStyle`
@font-face {
    font-family: 'Courgette';
    src: url('../../assets/fonts/Courgette-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url('../../assets/fonts/Lato-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-size: 100%;
    color: ${setColor.mainBlack};
    background: ${setColor.mainWhite};
    ${setFont.main};
}
h1 {
    font-size: 3em;
    line-height: 1.2;
    margin-bottom: 0.5em;
}
h2 {
    font-size: 2em;
    margin-bottom: 0.75em;
}
h3 {
    font-size: 1.5em;
    line-height: 1;
    margin-bottom: 1em;
}
h4 {
    font-size: 1.2em;
    line-height: 1.2;
    margin-bottom: 1.25em;
    font-weight: bold;
}
h5 {
    font-size: 1em;
    margin-bottom: 1.5em;
    font-weight: bold;
}
h6 {
    font-size: 1em;
    font-weight: bold;
}
p {
    line-height: 1.5;
    margin: 0 0 1.5rem 0;
}
`;

export default Globals;
